import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './static/fontawesome-pro-5-web/css/all.css';
import App from './App';
import { storesContext } from './contexts';
import stores from './stores';
import { BrowserRouter as Router } from 'react-router-dom';
import ShopHome from "./ShopApp";
//document.domain = import.meta.env.REACT_APP_DOMAIN || "lifeinfoapp.com";

//import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <storesContext.Provider value={stores}>
      <Router>
        <ShopHome />
      </Router>
    </storesContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
