import React, { useEffect } from "react";
import {  Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import './App.css';
import { useStores } from "./hooks/use-stores";
import { I18nextProvider } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import AuthRoute from "./hocs/AuthRoute";
import SignIn from "./containers/SignIn";
import ShopHome from "./containers/shop";
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import {ViewContext} from "./contexts/viewContext";
import ConfirmationModal from "./containers/ConfirmationModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrivateRouteWithGuest from "./hocs/PrivateRouteWithGuest";
import SnackbarContainer from "./containers/SnackbarContainer";
import Recovery from "./containers/Recovery";
import useMediaQuery from "@material-ui/core/useMediaQuery";



const useStyles = makeStyles((theme) => ({
    progressContainer: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        height: "100vh"
    }
}));


const StarfishHead = () => (
    <Helmet>
        <link rel="apple-touch-icon" href={`/starfish.png`} />
        <link rel="icon" href={`/Startfish_Black.png`} />
        <link rel="manifest" href={`/manifest.json`} />
        <meta name="description" content="Starfish Marketplace" />
        <title>Starfish Marketplace</title>
    </Helmet>
);

export default observer(function ShopApp() {
    const { commonStore, authStore } = useStores();
    const { languagesLoaded, appLoaded } = commonStore;
    const classes = useStyles();




    useEffect(()=>{
        commonStore.setOnStarfish(true)
    },[])


    useEffect(() => {
        var starfishManifest = {
            "name": "Starfish Marketplace",
            "short_name": "Starfish Marketplace",
            "start_url": "https://my.lifeinfoapp.com/starfish",
            "display": "standalone",
            "icons": [
                {
                    "src": `https://firebasestorage.googleapis.com/v0/b/life-info-b7c3e.appspot.com/o/images%2FStartfish_Black.png?alt=media&token=2f3d294d-244c-46d8-bd22-35407e3a45d4`,
                    "sizes": "512x512",
                    "type": "image/png"
                },
                {
                    "src": `/Startfish_Black.jpg`,
                    "sizes": "512x512",
                    "type": "image/jpeg"
                }
            ]
        };
        const stringManifest = JSON.stringify(starfishManifest);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#manifest-pwa').setAttribute('href', manifestURL);

    }, []);

    //Emit initial  loading
    useEffect(() => {
        (async () => {
            try {
                await commonStore.loadLanguages();
                await commonStore.loadStatus();
                await authStore.pullUser();
            }
            catch (e) {
                console.error(e);
            }
            finally {
                await commonStore.setAppLoaded();
            }

        })();
    }, []);

    const themeOptions = (isDark) => ({
        palette: {
            type: isDark ? 'dark' : 'light',
            primary: {
                // light: will be calculated from palette.primary.main,
                main: '#007BFF',
                // dark: will be calculated from palette.primary.main,
                contrastText: '#FFFFFF'
            },
            info:{
                // light: will be calculated from palette.primary.main,
                main: '#007BFF',
                // dark: will be calculated from palette.primary.main,
                contrastText: '#FFFFFF'
            },
            contrastThreshold: 3,
            // Used by the functions below to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
        },

        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 1180,
                lg: 1380,
                xl: 1920,
            },
        },
    });
    const theme = createTheme(themeOptions(true));
    const lightTheme = createTheme(themeOptions(false));

    const isPhone = useMediaQuery(theme.breakpoints.down(700));
    const isShopping =  true
    const gradientBg = isShopping

    const [frameLess, setFrameLess] = React.useState(false);
    const [frameLessRequested, setFrameLessRequested] = React.useState(false);

    const updateFrameLess = () => {
        setFrameLess( isPhone && frameLessRequested);
    }
    useEffect(() => {
        updateFrameLess();
    },[isPhone, frameLessRequested ])

    const value = { frameLess, setFrameLess:setFrameLess, frameLessRequested, setFrameLessRequested };


    return (
        <>
            {languagesLoaded ?
                <ViewContext.Provider value={value}>
                    <StarfishHead />
                    <I18nextProvider i18n={commonStore.i18n}>
                        <div className={`App ${gradientBg ? 'bg-dark-gradient' : ''}`}
                                                              style={ { ...(isShopping?{ overflowX: "hidden"}:{})  }  }
                                                              version={import.meta.env.REACT_APP_VERSION}>
                                    <ThemeProvider theme={theme}>
                                        {appLoaded ? <Switch>

                                            <AuthRoute path="/signin" exact component={SignIn} theme={lightTheme} />
                                            <AuthRoute path="/accountrecovery" exact component={Recovery} theme={lightTheme} />
                                            <PrivateRouteWithGuest path="/" render={()=>{
                                                return <ShopHome shoppingRoot={"/"} shoppingPath={"/"} standalone />
                                            }}/>
                                        </Switch> : <div className={classes.progressContainer}><CircularProgress size={90} /></div>}
                                        <SnackbarContainer />
                                        <ConfirmationModal />
                                    </ThemeProvider>
                                </div>

                    </I18nextProvider>
                </ViewContext.Provider>
                : ""}
        </>
    );
});
